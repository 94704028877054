import * as React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import '../styles/components/_header.scss';

const Header = ({ siteTitle }) => (
<header className="header">
	<Helmet>
		<link rel="alternate" type="application/feed+json" href="feeds/updates-feed.json" title="Jesse Rivest Music: Updates: JSON" />
		<link rel="alternate" type="application/json" href="feeds/updates-feed.json" title="Jesse Rivest Music: Updates: JSON" />
		<link rel="alternate" type="application/atom+xml" href="feeds/updates-feed.xml" title="Jesse Rivest Music: Updates: ATOM" />
	</Helmet>

	<h1 className="header__text">{siteTitle}</h1>

	<div className="header--flexbox">
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//open.spotify.com/artist/1AaY7NYQidTReRR6t1l9OF?si=VzCcd6TkRtODNcUsZpoL5g">
				<StaticImage
				src="../images/iconmonstr-spotify-5.png"
				alt="Spotify icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//jesserivest.bandcamp.com/music/">
				<StaticImage
				src="../images/iconmonstr-ish-bandcamp-5.png"
				alt="Bandcamp icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//instagram.com/jesserivest">
				<StaticImage
				src="../images/iconmonstr-instagram-10.png"
				alt="Instagram icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//youtube.com/@jesserivest">
				<StaticImage
				src="../images/iconmonstr-youtube-5.png"
				alt="Youtube icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//bnds.us/v2gteg">
				<StaticImage
				src="../images/iconmonstr-ish-bandsintown-5.png"
				alt="Bandsintown icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
		<div className="header--flexbox__icon">
			<a className="header--flexbox__icon__link" href="//twitter.com/jesserivest">
				<StaticImage
				src="../images/iconmonstr-twitter-5.png"
				alt="Twitter icon"
				placeholder="blurred"
				layout="constrained"
				className="header--flexbox__icon__link__img"
				width={50}
				/>
			</a>
		</div>
	</div>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
